import React, { Component } from 'react'
import Layout from '../layout/layout.jsx'
import Helmet from 'react-helmet'
import ScrollManager from '../helper/scrollManager.jsx'
import Circle from '../components/graphic/circle.jsx'
import posed from 'react-pose'
import { easeFunction } from '../helper/variables.jsx'
import { connect } from 'react-redux'
import Footer from '../components/common/footer.jsx'
import CTA from '../components/common/cta'

import './services.css'
import HeadImage from '../components/common/headImage.jsx'
import HeroBlock from '../components/serviceBlocks/heroBlock.jsx'
import ServiceBlock from '../components/serviceBlocks/serviceBlock.jsx'
import ClientBlock from '../components/serviceBlocks/clientBlock.jsx'
import ApproachBlock from '../components/serviceBlocks/approachBlock.jsx'
import CollaborateBlock from '../components/serviceBlocks/collaborateBlock.jsx'
import { graphql } from 'gatsby'
import ApproachTitle from '../components/serviceBlocks/approachTitle.jsx'
import { BrowserView, isBrowser } from 'react-device-detect'
import VisibilityWrapper from '../helper/visibilityWrapper.jsx'
import SectionIndicator from '../components/common/sectionIndicator.jsx'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class Services extends Component {
  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    triggerPointApproach: 0,
    triggerPointCollaborate: 0,
    triggerPointCTA: 0,
    triggerPointFooter: 0,
    sectionTitles: [
      'Capabilities',
      'Our Clients',
      'Our Approach',
      'Become a Partner',
    ],
    sectionTitle: '',
    onApproach: false,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionApproach = React.createRef()
    this.sectionCollaborate = React.createRef()
    this.sectionCTA = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.getTriggerPoints()

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.getTriggerPoints)

    this.setState({
      mainNode: this.mainNode.current,
    })

    this.props.hideSymbol()
    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.getTriggerPoints)

    this.props.backgroundWhite()
  }

  getTriggerPoints = () => {
    const { momentumScrollValue } = this.state

    this.setState({
      triggerPointCTA:
        momentumScrollValue +
        this.sectionCTA.current.getBoundingClientRect().top,
      triggerPointApproach:
        momentumScrollValue +
        this.sectionApproach.current.getBoundingClientRect().top,
      triggerPointCollaborate:
        momentumScrollValue +
        this.sectionCollaborate.current.getBoundingClientRect().top,
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointCTA - (isBrowser ? 75 : 55)) {
      this.props.backgroundRed()
    } else if (
      value >
      this.state.triggerPointCollaborate - (isBrowser ? 75 : 55)
    ) {
      this.props.backgroundWhite()
    } else if (value > this.state.triggerPointCollaborate - 300) {
      this.props.backgroundBlack()
      this.setState({
        onApproach: false,
      })
    } else if (
      value >
      this.state.triggerPointApproach - (isBrowser ? 75 : 55)
    ) {
      this.props.backgroundBlack()
      this.setState({
        onApproach: true,
      })
    } else if (value > this.state.triggerPointApproach - 100) {
      this.setState({
        onApproach: true,
      })
    } else {
      this.props.backgroundWhite()
      this.setState({
        onApproach: false,
      })
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  render() {
    const page = this.props.data.contentfulServiceLanding
    const services = this.props.data.allContentfulServices.edges
    const { theme } = this.props
    const { momentumScrollValue, sectionTitle, onApproach } = this.state

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta property="og:url" content="https://www.baunfire.com/services" />
          <link rel="canonical" href="https://www.baunfire.com/services" />
          <script>
            {`  if (typeof fbq === 'undefined') {  
            !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '588053714946302');
    fbq('track', 'PageView');
    fbq('track', 'Lead');
    }else {
      fbq('track', 'PageView');
      fbq('track', 'Lead'); 
    }
    `}
          </script>
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <Circle theme={theme} />
            <div className="page-services-landing">
              <HeroBlock
                page={page}
                momentumScrollValue={momentumScrollValue}
              />
              <HeadImage
                fluid={page.headImage.fluid}
                momentumScrollValue={momentumScrollValue}
              />
              <ServiceBlock page={page} services={services} />
              <ClientBlock page={page} />
              <VisibilityWrapper partialVisibility={true}>
                {({ isVisible }) => {
                  return (
                    <section
                      ref={this.sectionApproach}
                      className={`area-approach ${isVisible && 'appear'}`}
                    >
                      <ApproachBlock
                        theme={theme}
                        page={page}
                        momentumScrollValue={momentumScrollValue}
                      />
                    </section>
                  )
                }}
              </VisibilityWrapper>
              <section
                ref={this.sectionCollaborate}
                className="area-collaborate"
              >
                <CollaborateBlock theme={theme} page={page} />
              </section>

              <VisibilityWrapper partialVisibility={true}>
                {({ isVisible }) => {
                  return (
                    <div className={`area-cta ${isVisible && 'appear'}`}>
                      <div className="content-container">
                        <section className="section-cta" ref={this.sectionCTA}>
                          <CTA momentumScrollValue={momentumScrollValue} />
                        </section>
                      </div>
                    </div>
                  )
                }}
              </VisibilityWrapper>
            </div>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <div className="section-indicator type-comp3">{sectionTitle}</div>
          <BrowserView>
            <ApproachTitle trigger={onApproach} title={page.approachTitle} />
          </BrowserView>
          <BrowserView>
            <SectionIndicator theme={theme}>
              {page.heroRibbonText}
            </SectionIndicator>
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query($page: String = "Service landing") {
    contentfulServiceLanding(title: { eq: $page }) {
      metaData {
        title
        metaTitle
        description
        keywords
      }
      heroRibbonText
      heroHeadline
      heroDescription
      headImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      serviceListTitle
      clientsListTitle
      clients {
        id
        fixed(width: 170, quality: 90) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
        fluid(maxWidth: 240, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      approachTitle
      approaches {
        id
        title
        description
        animation {
          id
          file {
            url
          }
        }
      }
      collaborateTitle
      collaborateKeyImage {
        fluid(maxWidth: 680, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      collaborateSubscriptionTitle
      collaborateSubscriptionDescription
      collaborateProjectTitle
      collaborateProjectDescription
    }
    allContentfulServices(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedServices = connect(
  mapStateToProps,
  mapDispatchToProps
)(Services)

export default ConnectedServices
